@import '/src/styles/variables';

.container {
  border: 1px solid $input-border-color;
  //   overflow: auto;

  & > *:not(:last-child) {
    // position: relative;
    // &::after {
    //   content: ' ';
    //   position: absolute;
    //   top: calc(100% - 1px);
    //   left: 0;
    //   height: 1px;
    //   width: 100%;
    //   background-color: $input-border-color;
    // }
    border-bottom: 1px solid $input-border-color;
  }
}
