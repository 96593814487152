.container {
  flex: 1;
}

.select {
  width: 100%;
  white-space: pre-line;

  select {
    width: 100%;
  }
}
