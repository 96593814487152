@import '~bulma/sass/utilities/initial-variables.sass';

$family-sans-serif: 'Inter', BlinkMacSystemFont, -apple-system, 'Segoe UI',
  'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

$text: #000;

$control-radius: 0px;
$input-radius: $control-radius;
$button-padding-vertical: 0px;
$control-padding-vertical: 0px;

$input-placeholder-color: #909090;
$input-border-color: #9b9b9b;
$button-background-color: #d9d9d9;

$primary: #d9d9d9;
$primary-invert: #000;

$input-hover-color: $input-border-color;
$input-hover-border-color: $input-border-color;

$highlight-color: #00b2ff;
$input-focus-border-color: $highlight-color;
$input-focus-box-shadow-color: $input-focus-border-color;
$input-focus-box-shadow-size: 0 0 0 0rem;

$navbar-item-img-max-height: 58px;
$navbar-item-hover-background-color: none;

$label-weight: 400;
$label-color: #000;

$purple: #b2a1cc;

$purple-invert: findColorInvert($purple);
$custom-colors: (
  'purple': (
    $purple,
    $purple-invert,
  ),
);

$form-breakpoint: 510px;
