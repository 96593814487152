@import './variables';
@import '~bulma/bulma.sass';
@import '~react-datepicker/dist/react-datepicker.css';

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local('Inter'), local('Inter'),
    url('./fonts/Inter-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local('Open Sans Semibold'), local('Inter-Semibold'),
    url('./fonts/Inter-Medium.woff') format('woff');
}

.button {
  border: 1px solid #939393 !important;
}

.highlight {
  border: 1px solid $highlight-color !important;
}

.nav-item-no-hover {
  &:hover,
  &:active {
    background-color: none !important;
    color: inherit !important;
  }

  cursor: auto !important;
}

.select {
  select:not([multiple]) {
    padding-right: 3em;
  }
  &::before {
    content: url('./svgs/select-arrow.svg');
    position: absolute;
    pointer-events: none;
    top: 8px;
    right: 10px;
    z-index: 10;
  }
  &::after {
    background-color: #b6b6b6;
    border: 0 !important;
    width: 36px !important;
    height: 36px !important;
    transform: none !important;
    top: 2px !important;
    bottom: 2px !important;
    right: 2px !important;
    margin: 0 !important;
    border-radius: 0 !important;
  }
}

.checkbox {
  position: relative;

  [type='checkbox'] {
    display: none;
  }

  [type='checkbox'] + span {
    display: flex;
    align-items: center;
  }

  [type='checkbox'] + span::before {
    width: 40px;
    height: 40px;
    border: 1px solid $input-border-color;
    margin-right: 7px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: transparent;
    content: '1';
  }

  [type='checkbox']:checked + span::before {
    content: url('./svgs/tick.svg');
  }

  [type='checkbox']:disabled + span::before {
    // opacity: 0.1;
    border: 1px solid rgba($input-border-color, 0.1);
    background-color: rgba($input-border-color, 0.1);
  }

  &--small {
    font-size: 12px;

    [type='checkbox'] + span::before {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      padding-top: 0;
      line-height: 1;
      align-items: center;
    }

    [type='checkbox']:checked + span::before {
      content: url('./svgs/tick-small.svg');
      line-height: 1;
    }
  }
}

.label {
  margin-bottom: 3px !important;
}

.react-datepicker-popper {
  z-index: 20;
}

.loading {
  margin-top: 150px;
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}
