@import '/src/styles/variables';

.container {
  .field {
    margin-bottom: 0 !important;
  }
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  & > * {
    margin-bottom: 0 !important;
  }

  & > *:not(:last-child) {
    margin-right: 25px;
  }
}

.date {
  width: 146px;
}

.time {
  width: 117px;
}

.description {
  flex: 1;
}

.delete {
  visibility: hidden;
  pointer-events: none;

  &--visible {
    visibility: visible;
    pointer-events: auto;
  }
}

@media only screen and (max-width: $form-breakpoint) {
  .date {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 20px !important;
  }
}

@media only screen and (max-width: 389px) {
  .buttons {
    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
  // .buttons {
  //   flex-direction: column-reverse;

  //   & > * {
  //     margin-right: 0 !important;
  //     margin-bottom: 10px !important;
  //   }
  // }

  .delete {
    display: none;

    &--visible {
      display: block;
    }
  }
}

// .grid {
//   width: 429px;
//   display: grid;
//   grid-template-columns: 146px 1fr 1fr;
//   grid-column-gap: 25px;
//   grid-row-gap: 22px;
//   margin-bottom: 25px;
// }

// .date {
//   margin-bottom: 0 !important;
// }

// .home-office {
//   margin-bottom: 0 !important;
// }

// .description {
//   grid-column: 1 / -1;
// }

// .button-group {
//   grid-column: 1 / -1;
//   width: 100%;
//   display: flex;
// }

// .error {
//   grid-column: 1 / -1;
// }

// .cancel {
//   margin-right: 25px;
// }

// @media only screen and (max-width: $form-breakpoint) {
//   .grid {
//     width: 100%;
//     grid-template-columns: 1fr 1fr;
//     grid-row-gap: 20px;
//   }

//   .date {
//     grid-column: 1 / -1;
//     .control {
//       display: flex;
//     }
//   }

//   .home-office {
//     order: 4;
//   }

//   .error {
//     order: 5;
//   }

//   .button-group {
//     order: 6;
//   }

//   .cancel {
//     margin-right: 10px;
//   }
// }
