.tiny {
  max-width: 300px;
}

.small {
  max-width: 400px;
}

.medium {
  max-width: 500px;
}

.large {
  max-width: 600px;
}

.full {
  width: 100%;
}
