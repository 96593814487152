@import '/src/styles/variables';

::-webkit-scrollbar {
  display: none;
}

.container {
  padding: 5px 0;
  cursor: pointer;
  overflow: auto;
  white-space: nowrap;
  display: flex;

  & > * {
    padding-left: 7px;
    padding-right: 7px;
    flex-shrink: 0;

    &:not(:last-child) {
      border-right: 1px solid black;
    }
  }

  &:hover {
    background-color: #f8f8ff;
  }
}

.date {
  width: 103px;
}
.time {
  width: 120px;
}

.empty {
  color: transparent;
  cursor: default;
}

.locked {
  color: rgba(#000, 0.5);
  cursor: not-allowed;

  & > * {
    &:not(:last-child) {
      border-right: 1px solid rgba(#000, 0.5);
    }
  }
}

.error {
  background-color: rgba($red, 0.3);

  &:hover {
    background-color: rgba($red, 0.4);
  }
}
