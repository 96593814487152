.container {
  flex: 1;
}

.select {
  width: 100%;
  white-space: pre-line;

  select {
    width: 100%;
  }
}

@media only screen and (max-width: 550px) {
  .footer {
    flex-direction: column;
    margin-top: 15px !important;

    & > p {
      margin-bottom: 15px;
    }
  }
}
